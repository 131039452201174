.Privacy__container {
  padding: 3rem;
}
.Privacy__container h2 {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  font-feature-settings: "liga" off;
  color: black;
}
.Privacy__container span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 40px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: black;
}
.Sub__container {
  padding-left: 3rem;
  padding-right: 8rem;
}
@media (max-width: 768px) {
  .Sub__container {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
