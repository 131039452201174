.home-body{
    background: black;
}
.home-title{
    font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 60px;
line-height: 72px;
letter-spacing: -0.025em;
font-feature-settings: 'liga' off;
color: #FFFFFF;
}
.Home-section{
    display: flex;
    flex-direction: row;
    column-gap: 5rem;
    height: 46rem;
}
.section-one{
    margin-left: 5rem;
    margin-top: 11rem;
}
.home-text{
     font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 28px;
font-feature-settings: 'liga' off;
color: #FFFFFF;
margin-top: 1rem;
}
.home-button{
    width: 138px;
height: 56px;
background: #7C3AED;
border-radius: 8px;
}
.btn-text{
     font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
text-align: center;
font-feature-settings: 'liga' off;
color: #FFFFFF;
padding-top: 0.8rem;
}
.home-buttons{
    display: flex;
    flex-direction: row;
    column-gap: 3rem; 
    margin-top: 1.5rem;
}
.icon{
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
}
.home-image{
    width: auto;
    height: 42rem;
    margin-right: 8rem;
    margin-top: 4rem;
}
@media(max-width: 768px){
    .Home-section{
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        height: 60rem;
    }
    .section-one {
        margin-left: 1.5rem;
        margin-top: 2rem;
      }
.home-image {
    width: 100%;
    height: 31rem;
    margin-top: 1rem;
    margin-right: 0rem;
}
.home-title {
    font-size: 37px;
}
}
@media(min-width:1700px){
.Home-section{
    column-gap: 15rem;
}
.counter-section{
    column-gap: 19rem;
}
.about-section {
    column-gap: 6rem;
}

.footer-section {
    column-gap: 52rem;
}
}