.Testimonial{
    background: #4749C1;
}
.testimonial-title{
 font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 60px;
letter-spacing: -0.025em;
font-feature-settings: 'liga' off;
color: #FFFFFF;
margin-left: -2.8rem;
margin-top: 9rem;
}
.testimonial-text{
 font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 18px;
font-feature-settings: 'liga' off;
color: #FFFFFF;
margin-top: -2rem;
margin-left: -2.8rem;
}
.test-name{
 font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 28px;
font-feature-settings: 'liga' off;
color: #111827;
}
.test-position{
 font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
font-feature-settings: 'liga' off;
color: #6B7280;
margin-top: -1rem;
}
.background{
background: #FFFFFF;
box-shadow: 0px 4px 8px -2px rgba(17, 24, 39, 0.1), 0px 2px 4px -2px rgba(17, 24, 39, 0.06);
border-radius: 8px;
}
.test-text{
 font-family: 'Open Sans', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 28px;
font-feature-settings: 'liga' off;
color: #111827;
}
.symbol{
    position: relative;
    left: 1rem;
    top: 2rem;
}
.real{
    display: flex;
    flex-direction: row;
    margin-left: 5rem;
}
.testimonial-symbol{
    opacity: 0.5;
    margin-top: 5rem;
}
.wrap-testimonials{
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
    align-items: center;
    justify-content: center;
}
.wrap-sections{
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
}
.background p{
    padding-left: 3rem;
    padding-right: 2rem;
}
.testimonial-sectionOne{
    width: 450px;
}
.testimonial-sectionTwo{
    width: 400px;
margin-bottom: 6rem;   
}
.testimonial-section{
    width: 337px; 
}
@media(max-width:768px){
    .real {
        margin-left: 1rem;
      }
      .wrap-testimonials {
        flex-direction: column;
        row-gap: 3rem;
        align-items: center;
        justify-content: center;
      }
  
      .background p {
        padding-left: 2rem;
      }
      .test-text {
        padding-top: 0.7rem;
      }
      .testimonial-symbol {
        opacity: 0.5;
        margin-top: 2.6rem;
        width: 100%;
      }
      .testimonial-title {
        margin-top: 4rem;
      }
      .testimonial-sectionOne,.testimonial-sectionTwo,.testimonial-section {
        width: 277px; 
      }
}